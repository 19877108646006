import route from "ziggy";

export const ZiggyVue = {
    install: (v, options) => {
        const r = (name, params) => route(name, params, false, options);

        v.mixin({
            methods: {
                route: r,
            },
        });

        if (parseInt(v.version) > 2) {
            v.provide("route", r);
        }
    },
};
