import configure from "@/Plugins/configure.js";
import { createInertiaApp } from "@inertiajs/vue3";
import axios from "axios";
import { createApp, h } from "vue";
import "../css/app.css";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

import Echo from "laravel-echo";

import Pusher from "pusher-js";
window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: "reverb",
    key: "laravel-herd",
    wsHost: "localhost",
    wsPort: 8080,
    wssPort: 8080,
    forceTLS: false,
    enabledTransports: ["ws", "wss"],
});

createInertiaApp({
    progress: {
        color: "#0E5425",
    },
    title: (title) => `LemonSwan - ${title} `,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) });
        configure(vueApp.use(plugin)).mount(el);
    },
});
