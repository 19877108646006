import { Cropper } from "vue-advanced-cropper";
import i18n from "@/Plugins/i18n.js";
import { Link } from "@inertiajs/vue3";
import VueScrollPicker from "vue-scroll-picker";
import VueSocialSharing from "vue-social-sharing";
import route from "ziggy";
import { ZiggyVue } from "@/Plugins/ziggy.js";
import { Ziggy } from "../ziggy";
import FloatingVue from "floating-vue";

window.route = (name, params) => route(name, params, false, Ziggy);

export default function (app) {
    return app
        .use(i18n)
        .use(VueScrollPicker)
        .use(VueSocialSharing)
        .use(ZiggyVue, Ziggy)
        .use(FloatingVue)
        .component("Link", Link)
        .component("Cropper", Cropper);
}
