import de from "@/Lang/de.json";
import en from "@/Lang/en.json";
import ch from "@/Lang/de-ch.json";

import { createI18n } from "vue-i18n";

export default createI18n({
    locale: window.config?.locale ?? "de",
    fallbackLocale: window.config?.fallbackLocale ?? "de",
    legacy: false,
    globalInjection: true,
    messages: {
        en,
        de,
        "de-ch": ch,
    },
});
